import Matter from 'matter-js';
import config from './../config.json';

export default function Ball(engine, x, y) {
    const body = Matter.Bodies.circle(x, y, config.ball.size);

    body.restitution = 1;
    body.frictionAir = 0.015;
    body.mass = 20;
    body.render.fillStyle = config.ball.color;
    body.render.strokeStyle = body.render.fillStyle;

    Matter.World.add(engine.world, [body]);

    return body;
}
