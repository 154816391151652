import Matter from 'matter-js';
import config from './../config.json';

export default function Wall(engine, x, y, w, h) {
    const body = Matter.Bodies.rectangle(x, y, w, h, {isStatic: true});

    body.restitution = 1;
    body.render.fillStyle = config.walls.color;
    body.render.strokeStyle = body.render.fillStyle;

    Matter.World.add(engine.world, [body]);

    return body;
}
