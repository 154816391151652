import Matter from 'matter-js';
import config from './../config.json';

export default function Player(side, engine, x, y) {
    const body = Matter.Bodies.circle(x, y, config.player.size);

    body.restitution = 0.5;
    body.mass = 100;
    body.frictionAir = 0.15;
    body.render.fillStyle = (side === 'left') ? config.player.color[0] : config.player.color[1];

    Matter.World.add(engine.world, [body]);

    body.side = side;

    return body;
}
