import Matter from 'matter-js';
import { basicSetup, EditorView } from 'codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { oneDark } from '@codemirror/theme-one-dark';

import config from './config.json';
import Wall from './Objects/Wall';
import Ball from './Objects/Ball';
import Player from './Player';
import reverse from './reverse';

const Engine = Matter.Engine;
const Render = Matter.Render;
const Runner = Matter.Runner;
const Events = Matter.Events;
const Body = Matter.Body;

class Scoreboard {
    constructor(element) {
        this.element = element;
        this.left = this.element.querySelector('[data-scoreboard-side="left"]');
        this.right = this.element.querySelector('[data-scoreboard-side="right"]');
        this.timeEl = this.element.querySelector('[data-scoreboard-element="time"]');

        this.score = { left: 0, right: 0 };
        this.time = '00:00';

        this.timer = null;
    }

    setName(name, side) {
        this.element.querySelector(`[data-scoreboard-team="${side}"]`).innerHTML = name;
    }

    updateScore(score) {
        this.score = score;

        this.draw();
    }

    draw() {
        this.left.innerHTML = this.score.left;
        this.right.innerHTML = this.score.right;
    }

    startTime() {
        clearInterval(this.timer);

        this.timer = setInterval(() => this.increaseTime(), 1000);
    }

    stopTime() {
        clearInterval(this.timer);
    }

    resetTime() {
        clearInterval(this.timer);
        this.time = '00:00';
    }

    increaseTime() {
        let seconds = parseInt(this.time.split(':')[1], 10);
        let minutes = parseInt(this.time.split(':')[0], 10);

        seconds += 1;

        if (seconds >= 60) {
            seconds = 0;
            minutes += 1;
        }

        seconds = (seconds < 10) ? '0' + seconds : seconds;
        minutes = (minutes < 10) ? '0' + minutes : minutes;

        this.time = `${minutes}:${seconds}`;

        this.timeEl.innerHTML = this.time;
    }
}

class Game {
    constructor(element, scoreboard, playerAUpdateFunc, playerBUpdateFunc) {
        this.engine = Engine.create();
        this.runner = Runner.create();
        this.render = Render.create({
            element: element,
            engine: this.engine,
            options: {
                width: config.canvas.width,
                height: config.canvas.height,
                wireframes: false,
                background: 'transparent',
                showAngleIndicator: false,
            },
        });

        this.scoreboard = scoreboard;

        this.playerUpdate = {
            left: this.createPlayerUpdateFunc(playerAUpdateFunc),
            right: this.createPlayerUpdateFunc(playerBUpdateFunc),
        };

        this.score = { left: 0, right: 0 };
        this.maxScore = 3;

        this.ballLastX = null;
        this.ballLastY = null;
        this.ballSamePosNum = 0;
        this.force = 0.3;
        this.players = [];
        this.ball = null;

        this.configure();
        this.build();
        this.attach();
    }

    configure() {
        this.engine.world.gravity.y = 0;
    }

    attach() {
        Events.on(this.engine, 'beforeUpdate', (e) => this.update(e));
    }

    createPlayerUpdateFunc(updateFuncString) {
        return `(${updateFuncString})`;
    }

    build() {
        const w = config.canvas.width;
        const h = config.canvas.height;
        const wallWidth = config.walls.width;
        const wallHalf = wallWidth / 2;
        const fieldHalf = h / 2;
        const goalHalf = config.goal.height / 2;

        // Top
        Wall(this.engine, w / 2, wallHalf, w, wallWidth);

        // Bottom
        Wall(this.engine, w / 2, h - wallHalf, w, wallWidth);

        // Left
        Wall(this.engine, wallHalf, (fieldHalf - goalHalf) / 2, wallWidth, fieldHalf - goalHalf);
        Wall(this.engine, wallHalf, h - ((fieldHalf - goalHalf) / 2), wallWidth, fieldHalf - goalHalf);

        // Right
        Wall(this.engine, w - wallHalf, (fieldHalf - goalHalf) / 2, wallWidth, fieldHalf - goalHalf);
        Wall(this.engine, w - wallHalf, h - ((fieldHalf - goalHalf) / 2), wallWidth, fieldHalf - goalHalf);

        // Create the players
        this.players = [
            new Player(this.engine, 'left', 'goalKeeper', this.updatePlayer.bind(this)),
            new Player(this.engine, 'left', 'defender', this.updatePlayer.bind(this)),
            new Player(this.engine, 'left', 'attacker', this.updatePlayer.bind(this)),
            new Player(this.engine, 'right', 'goalKeeper', this.updatePlayer.bind(this)),
            new Player(this.engine, 'right', 'defender', this.updatePlayer.bind(this)),
            new Player(this.engine, 'right', 'attacker', this.updatePlayer.bind(this)),
        ]

        // Create the ball
        this.ball = Ball(this.engine, w / 2, h / 2);

        this.scoreboard.setName('HOM', 'left');
        this.scoreboard.setName('AWA', 'right');
    }

    start() {
        this.players.forEach((player) => player.start());

        Render.run(this.render);
        Runner.run(this.runner, this.engine);

        this.scoreboard.startTime();
    }

    update(e) {
        this.players.forEach((player) => this.requestUpdate(player));
        // this.requestUpdate(this.players[0]);
        this.updateBall();
    }

    requestUpdate(player) {
        const myPos = reverse(player.object.position, player.side !== 'left');
        const ballPos = reverse(this.ball.position, player.side !== 'left');
        const field = {
            w: config.canvas.width,
            h: config.canvas.height,
            goals: {
                left: {
                    x: 0,
                    y: config.canvas.height / 2,
                    height: config.goal.height,
                },
                right: {
                    x: config.canvas.width,
                    y: config.canvas.height / 2,
                    height: config.goal.height,
                },
            }
        }

        player.worker.postMessage([this.playerUpdate[player.side], {
            myPos,
            role: player.position,
            ballPos,
            field,
            score: this.score,
        }]);
    }

    updatePlayer(player, directions) {
        const force = { x: 0, y: 0 };

        // Top
        if (directions[0]) {
            force.y -= this.force;
        }

        // Right
        if (directions[1]) {
            if (player.side === 'left') {
                force.x += this.force;
            } else {
                force.x -= this.force;
            }
        }

        // Bottom
        if (directions[2]) {
            force.y += this.force;
        }

        // Left
        if (directions[3]) {
            if (player.side === 'left') {
                force.x -= this.force;
            } else {
                force.x += this.force;
            }
        }

        if (directions[0] || directions[1] || directions[2] || directions[3]) {
            Body.applyForce(player.object, player.object.position, force);
        }

        if (player.object.position.x < config.player.size) {
            const offset = config.player.size - player.object.position.x;

            Body.applyForce(player.object, player.object.position, { x: offset * 0.05, y: 0 });
        } else if (player.object.position.x > config.canvas.width - config.player.size) {
            const offset = (config.canvas.width - config.player.size) - player.object.position.x;

            Body.applyForce(player.object, player.object.position, { x: offset * 0.05, y: 0 });
        }
    }

    stopGame() {
        Render.stop(this.render);
        Render.stop(this.runner);

        this.scoreboard.stopTime();
    }

    updateBall() {
        if (this.ball.position.x < -config.ball.size) {
            this.scored('right');
        } else if (this.ball.position.x > config.canvas.width + config.ball.size) {
            this.scored('left');
        } else {
            const force = { x: 0, y: 0 };

            if (this.ballLastX === this.ball.position.x) {
                force.x = (Math.floor(Math.random() * 2) === 0) ? -0.00001 : 0.00001;
            } else if (this.ballLastY === this.ball.position.y) {
                force.y = (Math.floor(Math.random() * 2) === 0) ? -0.00001 : 0.00001;
            }

            Body.applyForce(this.ball, this.ball.position, force);
        }

        const ballXDiff = Math.abs(this.ballLastX - this.ball.position.x);
        const ballYDiff = Math.abs(this.ballLastY - this.ball.position.y);

        if (ballYDiff <= 0.6 && ballXDiff <= 0.6) {
            this.ballSamePosNum += 1;
        } else {
            this.ballSamePosNum = 0;
        }

        if (this.ballSamePosNum > 100) {
            this.resetBallPosition();
        }

        this.ballLastX = this.ball.position.x;
        this.ballLastY = this.ball.position.y;
    }

    scored(side) {
        this.score[side] += 1;

        this.scoreboard.updateScore(this.score);
        this.resetPositions();

        if (this.score.left >= this.maxScore || this.score.right >= this.maxScore) {
            this.stopGame();
        }
    }

    resetPositions() {
        this.resetPlayerPositions();
        this.resetBallPosition();
    }

    resetPlayerPositions() {
        this.players.forEach((player) => player.resetPosition());
    }

    resetBallPosition() {
        Body.setPosition(this.ball, {
            x: config.canvas.width / 2,
            y: config.canvas.height / 2,
        });
    }

    updatePlayerScript(script) {
        this.stopGame();

        this.playerUpdate.left = this.createPlayerUpdateFunc(script);
        this.score = { left: 0, right: 0 };

        this.scoreboard.updateScore(this.score);
        this.scoreboard.resetTime();

        this.resetPositions();
        this.start();
    }
}

function playerDefaultUpdateFunc(myPos, role, ballPos, field, score) {
    const directions = [0, 0, 0, 0];
    const goalHalfHeight = field.goals.left.height / 2;

    if (myPos.y > ballPos.y) {
        directions[0] = 1;
    } else if (myPos.y < ballPos.y) {
        directions[2] = 1;
    }

    if (role === 'goalKeeper') {
        directions[3] = myPos.x >= 25 ? 1 : 0;
        directions[1] = myPos.x <= 15 ? 1 : 0;

        if (myPos.y <= field.goals.left.y - goalHalfHeight) {
            directions[0] = 0;
        }

        if (myPos.y >= field.goals.left.y + goalHalfHeight) {
            directions[2] = 0;
        }
    } else {
        if (myPos.x < ballPos.x) {
            directions[1] = 1;
        } else if (myPos.x > ballPos.x) {
            directions[3] = 1;
        }
    }

    return directions;
}

window.onload = () => {
    const scoreBoard = new Scoreboard(document.getElementById('scoreboard'));
    const defaultFunction = playerDefaultUpdateFunc.toString();
    const game = new Game(document.getElementById('field'), scoreBoard, defaultFunction, defaultFunction);

    game.start();

    let timer;

    new EditorView({
        theme: 'material',
        doc: `function update(myPos, role, ballPos, field, score) {
    const directions = [0, 0, 0, 0];
    const goalHalfHeight = field.goals.left.height / 2;

    if (myPos.y > ballPos.y) {
        directions[0] = 1;
    } else if (myPos.y < ballPos.y) {
        directions[2] = 1;
    }

    if (role === 'goalKeeper') {
        directions[3] = myPos.x >= 25 ? 1 : 0;
        directions[1] = myPos.x <= 15 ? 1 : 0;

        if (myPos.y <= field.goals.left.y - goalHalfHeight) {
            directions[0] = 0;
        }

        if (myPos.y >= field.goals.left.y + goalHalfHeight) {
            directions[2] = 0;
        }
    } else {
        if (myPos.x < ballPos.x) {
            directions[1] = 1;
        } else if (myPos.x > ballPos.x) {
            directions[3] = 1;
        }
    }

    return directions;
}`,
        extensions: [
            basicSetup,
            javascript(),
            oneDark,
            EditorView.updateListener.of((v) => {
                if (v.docChanged) {
                    if( timer) clearTimeout(timer);

                    timer = setTimeout(() => {
                        game.updatePlayerScript(v.state.doc.toString());
                    }, 250 );
                }
            }),
        ],
        parent: document.getElementById('codeInput'),
    });
};
