{
    "canvas": {
        "width": 816,
        "height": 416,
        "background": "transparent"
    },
    "walls": {
        "width": 8,
        "color": "#000"
    },
    "goal": {
        "height": 120
    },
    "player": {
        "color": ["#df7437", "#8bcdca"],
        "size": 12
    },
    "ball": {
        "color": "#000",
        "size": 6
    },
    "startPositions": {
        "goalKeeper": {
            "x": 10,
            "y": 208
        },
        "defender": {
            "x": 50,
            "y": 168
        },
        "attacker": {
            "x": 100,
            "y": 248
        }
    }
}
