import Matter from 'matter-js';

const Body = Matter.Body;
import PlayerObject from './Objects/Player';
import config from './config.json';
import reverse from './reverse';

export default class Player {
    constructor(engine, side, position, updatePlayer) {
        this.position = position;
        this.startPos = reverse({ ...config.startPositions[position] }, side !== 'left');
        this.object = PlayerObject(side, engine, this.startPos.x, this.startPos.y);
        this.side = side;
        this.worker = null;
        this.updatePlayer = updatePlayer;
    }

    terminate() {
        if (this.worker) {
            this.worker.terminate();
        }
    }

    start() {
        if (this.worker) {
            this.worker.terminate();
            this.worker = null;
        }

        this.worker = new Worker(new URL('./sandbox.js', import.meta.url));

        this.worker.onmessage = e => {
            this.updatePlayer(this, e.data);
        };
    }

    resetPosition() {
        Body.setPosition(this.object, this.startPos);
    }
}
